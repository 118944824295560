import {createStore} from 'vuex'

import auth from "./module/auth";
import posts from "./module/posts";
import alert from "@/store/module/alert";
import zoom from "@/store/module/zoom";

const store = createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    posts,
    alert,
    zoom,
  }
});

export default store;

