import api from "../../lib/api";
import attach from "./attach";
import {AC_POST_CREATED} from "../actions";
import pagination from "@/store/module/pagination";

/**
 * vuex module posts.js
 */
export const NS_POSTS = 'posts';

const state = {
  data: [],
};

const getters = {
  posts: ({data}) => data.slice().reverse(),
  latest: ({data}) => data.slice().reverse().slice(-5),
};

const mutations = {
  data(state, data){
    state.data = data
  },
  more(state, data){
    state.data = state.data.concat(data);
  }
};

const actions = {
  load({commit}, more){
    const params = {expand: 'attachments', sort: '-created'};

    if(more){
      params.page = state.pagination.currentPage + 1;
    }

    return api.get('posts', {params}).then(({data, headers}) => {
      commit(more ? 'more' : 'data', data);
      commit('headers', headers);
    });
  },
  add({dispatch, state}, content){
    const attach = state.attach.files;
    return api.post('posts', {content, attach})
        .then(({data}) => dispatch(AC_POST_CREATED, data, {root: true}));
  },
  remove({dispatch}, {id}){
    return api.remove('posts', id).then(() => {dispatch('load')});
  },
  [AC_POST_CREATED]:{
    root: true,
    handler({dispatch}){
      dispatch('load');
    }
  },

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules:{
    attach,
    pagination,
  }
}
