<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-info">
    <div class="container-fluid">
      <router-link to="/" class="navbar-brand">Postnote</router-link>
      <button class="navbar-toggler" type="button"
              data-bs-toggle="collapse"
              data-bs-target="#postnote-navbar"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="postnote-navbar">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item" v-for="(item, index) in list" :key="index">
            <router-link
                :to="item.to"
                class="nav-link"
                active-class="active"
            >{{ item.label }}</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
  import {NS_AUTH} from "../store/module/auth";
  import {mapGetters} from "vuex";

  const nav = [
    // { to: '/about', label: 'About', guest: true},
    { to: '/posts', label: 'Posts', guest: false},
    { to: '/share', label: 'Share', guest: false},
  ];

  export default {
    name: "NavBar",
    computed: {
      ...mapGetters(NS_AUTH, ['isAuthenticated']),
      list(){
        return nav.filter(item => item.guest || this.isAuthenticated)
      }
    }
  }
</script>

<style scoped>
.logo{
  width: 10vw;
  height: auto;
}
</style>