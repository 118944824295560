<template>
  <div>
    <div :class="['drop-area', {'active': isOver}]"
         ref="dropArea"
         @dragenter="onDragEnter"
         @dragover="onDragOver"
         @dragleave="onDragLeave"
         @drop="onDrop"
    >
      <div class="row">
        <div v-for="({file, src},index) in files" :key="index"
             class="col-2"
        >
          <div class="card">
            <img :src="src">
            <div>
              {{ file.name }}, {{ file.type }}, {{ file.size }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {NS_POST_ATTACH} from "@/store/module/attach";
  import {mapActions} from "vuex";

  export default {
    name: "DropArea",
    data: () => ({
      isOver: false,
      files: [],
    }),
    methods:{
      ...mapActions(NS_POST_ATTACH, ['addFile']),
      eventStop(e){
        e.preventDefault();
        e.stopPropagation();
      },
      onDragEnter(e){
        this.eventStop(e);
        this.isOver = true;
      },
      onDragOver(e){
        this.eventStop(e);
        this.isOver = true;
      },
      onDragLeave(e){
        this.eventStop(e);
        this.isOver = false;
        this.$emit('complete');
      },
      onDrop(e){
        this.eventStop(e);
        this.isOver = false;
        this.$emit('complete');
        const {files} = e.dataTransfer;
        for(let i = 0; i < files.length; i++){
          const file = files.item(i);
          this.addFile(file);
        }
      },
    },
  }
</script>

<style scoped lang="less">
  .drop-area{
    position: fixed;
    z-index: 1001;
    top: 10vh;
    left: 10vw;
    width: 80vw;
    min-height: 80vh;
    background-color: rgba(255,255,255,0.9);
    box-shadow: 0px 0px 25px 35px rgba(0,0,0,0.35);
    border: #ccc 1px solid;
    border-radius: 15px;
    margin: 1vh 0;
    &.active{
      border-color: #51d451;
      background-color: rgba(173, 202, 139, 0.9);
    }
  }
</style>