<template>
  <div>
    <template v-if="imgCount < 3">
      <post-attachment-image
          v-for="file in images" :key="file.id" :file="file"
          @img-load="$emit('imgLoad', $event)"
      ></post-attachment-image>
    </template>
    <template v-else>
      <post-attachment-image
          :file="images[0]"
          @img-load="$emit('imgLoad', $event)"
      ></post-attachment-image>
      <div class="post-image-gallery">
        <div v-for="(file, index) in images" :key="file.id">
          <post-attachment-image
              :file="file" v-if="index > 0"
              @img-load="$emit('imgLoad', $event)"
          ></post-attachment-image>
        </div>
      </div>
    </template>
    <div class="post-attach-misc">
      <post-attachment v-for="file in misc" :key="file.id" :file="file"></post-attachment>
    </div>
  </div>
</template>

<script>
  import PostAttachmentImage from "./PostAttachmentImage";
  import PostAttachment from "./PostAttachment";

  export default {
    name: "PostAttachments",
    components: {PostAttachment, PostAttachmentImage},
    props: ['post'],
    computed:{
      attachments(){
        const result = {images: [], misc: []};
        for(const file of this.post.attachments){
          if(/^image\/.*/.exec(file.mime)){
            result.images.push(file);
          }else{
            result.misc.push(file);
          }
        }
        return result;
      },
      images(){ return this.attachments.images },
      misc(){ return this.attachments.misc },
      hasAttachments(){ return this.post.attachments.length > 0 },
      hasImages(){ return this.images.length > 0},
      imgCount(){ return this.images.length }
    },
  }
</script>

<style scoped>
  .post-image-gallery{
    display: flex;
  }
</style>