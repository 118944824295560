/**
 * vuex module pagination.js
 */
import {camelCase} from "lodash";

const state = {
  // -- headers
  totalCount: 0,
  perPage: 0,
  currentPage: 1,
  pageCount: 1,
//  --
  loadedPages:[]
};

const getters = {
  haveMore: ({currentPage, pageCount}) => pageCount > currentPage
};

const mutations = {
  headers: (state, headers) => {
    let pfx = 'x-pagination-';
    for (let name in headers) {
      if (name.indexOf(pfx) === 0) {
        let pgProp = camelCase(name.replace(pfx, ''));
        state[pgProp] = 1 * headers[name];
      }
    }
  },
  loadedPage(state, page){
    if(!state.loadedPages.includes(page)){
      state.loadedPages.push(page);
    }
  }
};

const actions = {
  registerPage({commit}, page){
    commit('loadedPage', page);
  },
};

export default {
  state,
  mutations,
  actions,
  getters
}
