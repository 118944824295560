<template>
  <div
     class="position-fixed fixed-top m-0 rounded-0"
     style="z-index: 2000;"
     v-show="show"
  >
    <div :class="['alert', cls]">
      {{ text }}
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
  </div>

</template>

<script>
  import {mapState} from "vuex";
  import {NS_ALERT} from "@/store/module/alert";

  export default {
    name: "ShowAlert",
    computed:{
      ...mapState(NS_ALERT, ['text', 'show', 'variant']),
      cls(){ return 'alert-' + this.variant}
    }
  }
</script>

<style scoped>

</style>