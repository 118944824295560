<template>
  <div class="post-attach-info">
    <div class="post-attach-name">
      📎 <a :href="downloadHref" download>{{ file.origin }}</a>
    </div>
    <div class="post-attach-size">{{ size }}</div>
    <div class="post-attach-type">{{ file.mime }}</div>
  </div>
</template>

<script>
  import {formatBytes} from "@/module/format";

  export default {
    name: "PostAttachment",
    props: ['file'],
    computed:{
      size(){
        return formatBytes(this.file.size);
      },
      downloadHref(){
        return '/api/attach/download?id=' + this.file.id;
      },
    }
  }
</script>

<style scoped>
  .post-attach-info{
    display: flex;
    justify-content: space-between;
  }
</style>