<template>
  <div class="home posts-page">
    <latest-posts class="posts-page-block"></latest-posts>
    <post-form class="posts-page-block"></post-form>
    <image-zoom></image-zoom>
  </div>
</template>

<script>
  import PostForm from "../components/posts/PostForm";
  import LatestPosts from "../components/LatestPosts";
  import {NS_POSTS} from "../store/module/posts";
  import ImageZoom from "../components/misc/ImageZoom";

  export default {
  name: 'HomeView',
  components: {
    ImageZoom,
    LatestPosts,
    PostForm,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.dispatch(NS_POSTS + '/load');
    })
  },
}
</script>
