import moment from "moment";

export function date(date, format = 'DD.MM.YY HH:mm') {
  return moment(date).format(format);
}

export function percent(value){
  return Math.round(1000 * value)/10;
}

export function float(value, prec = 2){
  let f = Math.pow(10, prec);
  return Math.round(f * value)/f;
}

export function int(value){
  return Math.round(value);
}

/**
 * https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
 *
 * @param bytes
 * @param decimals
 * @returns {string}
 */
export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}


