import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap';
import './style/style.scss';

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['auth/isAuthenticated'];
  if (to.name !== 'Login' && !isAuthenticated){
    next({ name: 'Login' });
  }else{
    next();
  }
});

const app = createApp(App);
app.use(store)
    .use(router)
    .mount('#app');
