<template>
  <div class="table-responsive">
    <table class="table table-sm">
      <tbody>
        <tr v-for="(file,index) in files" :key="index">
          <td class="text-start">{{ file.name }}</td>
          <template v-if="file.progress < 100">
            <td colspan="4">
              <div class="progress">
                <div class="progress-bar" :style="{width: file.progress + '%'}"></div>
              </div>
            </td>
          </template>
          <template v-else>
            <td>{{ file.type }}</td>
            <td>{{ formatBytes(file.size) }}</td>
            <td>
              <span class="btn btn-sm btn-outline-danger" @click="removeFile(index)" title="Удалить">&times;</span>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import {NS_POST_ATTACH} from "../../store/module/attach";
  import {mapActions, mapGetters} from "vuex";
  import {formatBytes} from "../../module/format";

  export default {
    name: "PostFormAttach",
    computed: {
      ...mapGetters(NS_POST_ATTACH, ['files']),
    },
    methods:{
      ...mapActions(NS_POST_ATTACH, ['removeFile']),
      formatBytes,
    },
  }
</script>

<style scoped>

</style>