/**
 * vuex module attach.js
 */
import api from "../../lib/api";
import {AC_POST_CREATED} from "../actions";
import {v1 as uuidv1} from "uuid";

export const NS_POST_ATTACH = 'posts/attach';

const state = {
  dragState: false,
  files: []
};

const getters = {
  files: ({files}) => files,
};

const mutations = {
  dragState(state, status){
    state.dragState = status;
  },
  files(state, data){
    state.files = data;
  },
  add(state, file){
    state.files.push(file);
  },
  update(state, {uuid, ...data}){
    const index = state.files.findIndex(f => f.uuid === uuid);
    if(index > -1){
      const obj = {...state.files[index], ...data}
      state.files.splice(index, 1, obj);
    }
  },
  remove(state, index){
    state.files.splice(index, 1);
  }
};

const actions = {
  setDragState({commit}, status){
    commit('dragState', status);
  },
  addFile({commit, dispatch}, file){
    const data = new FormData;
    data.append('attach', file);
    const uuid = uuidv1();
    const {name, type, size} = file;
    const fileObj = {uuid, name, type, size, progress: 0, preload: null};
    commit('add', fileObj);
    const config = {
      onUploadProgress: progressEvent => {
        const progress = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
        commit('update', {uuid, progress});
      }
    };
    // todo check allowed size
    api.post('attach/preload', data, config).then(({data}) => {
      // const file = {name, type, size, preload: data};
      commit('update', {uuid, preload: data});
    }).catch(error => dispatch('alert/apiError', error, {root: true}));
  },
  removeFile({commit}, index){
    commit('remove', index);
  },
  [AC_POST_CREATED]:{
    root: true,
    handler({commit}){
      commit('files', []);
    }
  },
};

/*const uploadProgressHandler = (progressEvent, uuid, commit) => {
  const progress = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
  commit('update', {uuid, progress});
};*/

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
