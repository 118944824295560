<template>
  <div class="posts">
    <div class="posts-wrapper" ref="scroller">
      <template v-if="latest.length > 0">
        <router-link to="posts" class="btn btn-link">К списку >></router-link>
        <post-item v-for="post in latest" :key="post.id"
            :post="post"
            @img-load="onImgLoad"
        ></post-item>
      </template>
      <template v-else>
        <p class="text-muted">Пока нет записок</p>
      </template>
    </div>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from "vuex";
  import {NS_POSTS} from "../store/module/posts";
  import PostItem from "./posts/PostItem";

  export default {
    name: "LatestPosts",
    components: {PostItem},
    data: () => ({
      initScroll: false
    }),
    computed:{
      ...mapGetters(NS_POSTS, ['latest']),
    },
    methods:{
      ...mapActions(NS_POSTS, ['load']),
      onImgLoad(){
        const scroller = this.$refs.scroller;
        if(scroller){
          scroller.scrollTo(0, scroller.scrollHeight);
        }
      }
    },
  }
</script>

<style scoped>

</style>