/**
 * vuex module zoom.js
 */

const state = {
  zoom: null
};

const getters = {
  zoom: state => state.zoom
};

const mutations = {
  zoom(state, data){
    state.zoom = data;
  }
};

const actions = {
  setZoom({commit}, data){
    commit('zoom', data);
  }
};

export default {
  state,
  mutations,
  actions,
  getters
}
