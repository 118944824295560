<template>
  <div class="modal fade" tabindex="-1" id="image-modal">
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-body pn-scrollbar">
          <img :src="src" class="img-fluid">
        </div>
        <div class="modal-footer">
          <a :href="src" download  class="btn btn-success">Скачать</a>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {Modal} from "bootstrap";
  import {mapActions, mapGetters} from "vuex";

  export default {
    name: "ImageZoom",
    data: () => ({
      modal: null
    }),
    computed:{
      ...mapGetters(['zoom']),
      src(){ return this.zoom }
    },
    methods:{
      ...mapActions(['setZoom']),
    },
    mounted(){
        const modal = new Modal(this.$el);
        this.$el.addEventListener('hidden.bs.modal', () => this.setZoom(null));
        this.modal = modal;
    },
    watch:{
      zoom(data){
        if(data){
          this.modal.show();
        }else{
          this.modal.hide();
        }
      }
    }
  }
</script>

<style scoped>

</style>