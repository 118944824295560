<template>
  <div id="app">
    <nav-bar></nav-bar>
    <show-alert></show-alert>
    <div class="container-fluid mt-2">
      <router-view/>
    </div>
    <div class="mt-5">
      <img alt="Gapp logo" src="./assets/logo.png">
    </div>
  </div>
</template>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<script>
  import NavBar from "./components/NavBar";
  import ShowAlert from "./components/misc/ShowAlert";
  export default {
    components: {ShowAlert, NavBar}
  }
</script>