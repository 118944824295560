import axios from "axios";

const BASE_URL = '/api';

const configModel = {
  timeout: 10000,
  headers: {
    'Content-type': 'application/json'
  },
};
let auth_key;
//
const get = function(task, config){
  return axios.get(BASE_URL + '/' + task, makeConfig(config)).catch(handleError)
};

const post = function (task, data, config = {...configModel}){
  return axios.post(BASE_URL + '/' + task, data, makeConfig(config))
};

const remove = function (task, id, config = {...configModel}){
  return axios.delete(BASE_URL + `/${task}/${id}`, makeConfig(config)).catch(handleError)
};


function makeConfig(config) {
  const result = {...configModel};
  if(config){
    Object.assign(result, config);
  }
  result.headers['Authorization'] = `Bearer ${auth_key}`;
  return result;
}

function handleError(error) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
  }
  console.log(error.config);
}

function setAuthKey(key) {
  auth_key = key;
}

export default {
  get,
  post,
  remove,
  setAuthKey
}