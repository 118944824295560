<template>
  <div class="post-form"
       @dragenter="onDrag"
       @dragover="onDrag"
  >
    <div class="post-form-input">
      <textarea class="form-control"
          v-model="content"
          placeholder="Оставить записку"
      ></textarea>
      <post-form-attach></post-form-attach>
    </div>
    <div class="post-form-panel">
      <div class="post-form-control post-form-attach">
        <file-picker @input="addFile"></file-picker>
      </div>
      <div class="post-form-control post-form-sender">
        <button class="btn btn-outline-info col-12" @click="save">Отправить</button>
      </div>
    </div>
    <drop-area @complete="dragOff" v-if="dragState"></drop-area>
  </div>
</template>

<script>
  import {mapActions} from "vuex";
  import {NS_POSTS} from "../../store/module/posts";
  import DropArea from "./DropArea";
  import PostFormAttach from "./PostFormAttach";
  import FilePicker from "../misc/FilePicker";
  import {NS_POST_ATTACH} from "../../store/module/attach";

  export default {
    name: "PostForm",
    components: {FilePicker, PostFormAttach, DropArea},
    data: () => ({
      content: '',
      dragState: false,
    }),
    methods:{
      ...mapActions(NS_POSTS, ['add']),
      ...mapActions(NS_POST_ATTACH, ['addFile']),
      save(){
        this.add(this.content).then(() => {
          this.content = '';
        });
      },
      onDrag(e){
        e.preventDefault();
        this.dragState = true;
      },
      dragOff(){
        this.dragState = false;
      }
    }
  }
</script>

<style scoped>
</style>