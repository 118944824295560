<template>
  <label class="file-picker">
    <span class="btn btn-outline-secondary"><slot>Выбрать файл..</slot></span>
    <input type="file" @change="handleFileChange" />
  </label>
</template>

<script>
  export default {
    name: "FilePicker",
    props: {
      value: File,
      multiple: {
        type: Boolean,
        default: false
      },
      target: {
        type: String,
        default: 'dataURL',
      },
    },
    methods: {
      handleFileChange(e) {
        const file = e.target.files[0];
        this.$emit('input', file);
      }
    }
  }
</script>

<style scoped lang="less">
  .file-picker{
    > input[type="file"] {
      display: none;
    }
  }
</style>