/**
 * vuex module auth.js
 */
import axios from "axios";
import api from "../../lib/api";
import QRCode from 'qrcode';

const LS_KEY = 'postnote-auth-key-name';

export const NS_AUTH = 'auth';

const key = localStorage.getItem(LS_KEY);
api.setAuthKey(key);
const state = {
  key,
  user: null,
  token: null,
  dataURL: null,
};

const getters = {
  isAuthenticated: ({key}) => key !== null,
  url: state => location.origin + '/login/' + state.token.token
};

const mutations = {
  key(state, value){
    state.key = value;
  },
  user(state, data){
    state.user = data;
  },
  token(state, data){
    state.token = data;
  },
  dataURL(state, data){
    state.dataURL = data;
  }
};

const actions = {
  check({commit}){
    return new Promise((resolve, reject) => {
      const authKey = localStorage.getItem(LS_KEY);
      if(authKey === null){
        reject()
      }
      commit('key', authKey);
      resolve();
    })
  },
  guest({commit, dispatch}){
    return axios.post('api/user/guest').then(({data}) => {
      const {authToken, ...rest} = data;
      dispatch('key', authToken);
      commit('user', rest)
    });
  },
  key({commit}, key){
    commit('key', key);
    localStorage.setItem(LS_KEY, key);
    api.setAuthKey(key);
  },
  getToken({commit, getters}){
    return api.post('auth-tokens').then(({data}) => {
      commit('token', data);
      QRCode.toDataURL(getters.url)
          .then(data => commit('dataURL', data))
          .catch(() => commit('dataURL', null))
    });
  },
  signin({commit, dispatch}, token){
    return api.get('auth-token/signup',{params:{token}}).then(({data}) => {
      const {authToken, ...rest} = data;
      dispatch('key', authToken);
      commit('user', rest)
    });
  },
  signout({commit}){
    return new Promise((resolve) => {
      localStorage.removeItem(LS_KEY);
      commit('key', null);
      api.setAuthKey(null);
      resolve();
    })
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
