<template>
  <div class="pn-post">
    <div class="pn-post-content">
      <p class="pn-post-time">{{ post.created }}</p>
      <div class="pn-post-text" v-html="content"></div>
    </div>
    <div class="pn-post-toolbar">
      <span class="btn btn-sm btn-outline-danger" @click="remove(post)" title="Удалить">&times;</span>
    </div>

    <post-attachments :post="post" @img-load="$emit('img-load', $event)"></post-attachments>
  </div>
</template>

<script>
  import {NS_POSTS} from "../../store/module/posts";
  import {mapActions} from "vuex";
  import PostAttachments from "./PostAttachments";

  export default {
    name: "PostItem",
    components: {PostAttachments},
    props: ['post'],
    computed:{
      content(){ return this.urlify(this.post.content)},
      attachments(){ return this.post.attachments; },
      hasAttachments(){ return this.post.attachments.length > 0 }
    },
    methods:{
      ...mapActions(NS_POSTS, ['remove']),
      urlify(text) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, url => '<a target="_blank" href="' + url + '">' + url + '</a>')
      },
    },
  }
</script>

<style scoped lang="less">
  .pn-post{
    min-height: 4vh;
    border-bottom: 1px dotted bisque;
    position: relative;
    > p{
      text-align: justify;
    }
    &-content{
      text-align: left;
    }
    .pn-post-toolbar{
      display: none;
      position: absolute;
      bottom: 1vh;
      right: 1vw;
    }
    &:hover{
      .pn-post-toolbar{
        display: initial;
      }
    }
  }
</style>
