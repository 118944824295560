/**
 * vuex module alert.js
 */

export const NS_ALERT = 'alert';
export const AC_ALERT_SUCCESS = NS_ALERT + '/success';
export const AC_ALERT_WARNING = NS_ALERT + '/warning';
export const AC_ALERT_DANGER = NS_ALERT + '/danger';

const state = {
  text: 'test',
  show: false,
  variant: 'info',
};

const getters = {};

const mutations = {
  text(state, text){
    state.text = text;
  },
  show(state, status){
    state.show = status;
  },
  variant(state, variant){
    state.variant = variant;
  },
};

const actions = {
  alert:{
    root: true,
    handler({commit}, {text, variant = 'info', show = true}){
      commit('text', text);
      commit('show', show);
      commit('variant', variant);
    }
  },
  warning({commit}, text){
    commit('text', text);
    commit('show', 5);
    commit('variant', 'warning');
  },
  success({commit}, text){
    commit('text', text);
    commit('show', 3);
    commit('variant', 'success');
  },
  danger({commit}, text){
    commit('text', text);
    commit('show', 10);
    commit('variant', 'danger');
  },
  apiError({dispatch}, error){
    let message;
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      message = error.response.data
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      message = 'no response';
    } else {
      // Something happened in setting up the request that triggered an Error
      message = error.message;
    }
    dispatch('danger', message);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
