import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/share',
    name: 'Add device',
    component: () => import(/* webpackChunkName: "share" */ '../components/ShareDevice')
  },
  {
    path: '/login/:token?',
    name: 'Login',
    component: () => import('../components/LoginScreen'),
    props: true
  },
  {
    path: '/posts',
    name: 'Posts',
    component: () => import(/* webpackChunkName: "posts" */ '../components/posts/PostList'),
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router
