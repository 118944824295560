<template>
  <div class="post-note-attach-img">
    <a href="#" target="_blank" @click.prevent="setZoom(downloadHref)">
      <img :src="thumbHref" @load="$emit('imgLoad', file.id)">
    </a>
  </div>
</template>

<script>
  import {formatBytes} from "@/module/format";
  import {mapActions} from "vuex";

  export default {
    name: "PostAttachmentImage",
    props:['file'],
    computed:{
      size(){
        return formatBytes(this.file.size);
      },
      thumbHref(){
        return '/api/attach/thumb?id=' + this.file.id;
      },
      downloadHref(){
        return '/api/attach/download?id=' + this.file.id;
      },
    },
    methods:{
      ...mapActions(['setZoom']),
    }
  }
</script>

<style scoped>
  .post-note-attach-img img{
    width: 100%;
    height: auto;
  }

</style>